import React, {useState} from 'react';
import '../../assets/styles/Banner.css';
import bannerImage from '../../assets/image/banner-image.png';
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import '../../assets/styles/Modal-Form.css';
import '../../assets/styles/Form.css';
import axios from "axios";

const Banner = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const onSubmit = data => {
        axios.post('https://guttergaur-company.netlify.app/.netlify/functions/api/mail', {data}).then(
            ()=>{window.location.replace('https://www.gutterguard.company/submit/success')}
        ).catch((error)=> {console.error(error)});
        reset();
    };


    return (
        <div className={'banner'}>
            <div className={'banner-image'}>
                <img src={bannerImage} alt={'banner'}/>
            </div>
            <div className={'banner-title'}>
                <h1>Gutter guard and gutter replacement, repair and installation</h1>
                <p>Get a free gutter and gutter guard consultation.</p>
                {/*<button className={'form-mobile-modal'} id={'form-mobile-modal'} onClick={toggleModal}>Fast free quote</button>*/}
            </div>
            {/*<Modal isOpen={isOpen} className={"form-mobile-modal-popup"}>*/}
            {/*    <div className={'modal-form'}>*/}
            {/*        <button className={'close'} onClick={()=> {setIsOpen(false)}}>Close</button>*/}
            {/*        <div className={'form-title'}>*/}
            {/*            <h3>Fast free quote</h3>*/}
            {/*        </div>*/}
            {/*        <div className={'form-description'}>*/}
            {/*            <p>Share your address and we will research your needs before we speak</p>*/}
            {/*        </div>*/}
            {/*        <form className={'email-form'} onSubmit={handleSubmit(onSubmit)}>*/}
            {/*            <input placeholder={errors.name ? "Please input your first name" : "Name"} type="text" className={errors.name ? "error" : ""}*/}
            {/*                   style={{border: errors.name ? "3px solid red" : "0"}} {...register("name", {required: true})}/>*/}
            {/*            <input placeholder={errors.email ? "Please input valid email" : "Email"} type="email" className={errors.email ? "error" : ""}*/}
            {/*                   style={{border: errors.email ? "3px solid red" : "0"}} {...register("email", {required: true})}/>*/}
            {/*            <input placeholder={errors.phone ? "Please input your phone" : "Phone"} type="tel" className={errors.email ? "error" : ""}*/}
            {/*                   style={{border: errors.phone ? "3px solid red" : "0"}} {...register("phone", {required: true})}/>*/}
            {/*            <input placeholder={errors.address ? "Please input your full address/zip" : "Full address/zip"}*/}
            {/*                   type="text" style={{border: errors.address ? "3px solid red" : "0"}}*/}
            {/*                   className={errors.phone ? "error" : ""} {...register("address", {required: true})}/>*/}
            {/*            <textarea className={errors.tellUs ? "error" : ""}*/}
            {/*                      placeholder={errors.tellUs ? "Please input enter your unique need" : "Tell us about your unique need"}*/}
            {/*                      style={{border: errors.tellUs ? "3px solid red" : "0"}} type="text"*/}
            {/*                      {...register("tellUs", {required: true})}></textarea>*/}
            {/*            <input type="submit"/>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </div>
    );
};

export default Banner;