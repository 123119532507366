import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import '../../assets/styles/Form.css';
import axios from "axios";
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import { Toast } from 'primereact/toast';

const Form = () => {

    let emptyCustomer = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        postCode: '',
        notes: ''
    };



    const form = useForm({ emptyCustomer });
    const errors = form.formState.errors;
    const toast = useRef(null);

    //const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const [customer, setCustomer] = useState(emptyCustomer);

    const onSubmit = (data) => {

        axios.post('https://mbg.gutterguard.company/api/Leads/CreateLead', { data })
            .then(



                (res) =>
                {
                    if (!res) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        return;
                    }
                    else {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Successful!', life: 3000 });
                    }

                window.location.replace('https://www.gutterguard.company/submit/success')
            }
        ).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            console.error(error);
        });
        //reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error"></small>;
    };


    return (

        <div className={'form-container'} id={'form-container'}>

            <Toast ref={toast} />

            <div className={'form-title'}>
                <h3>Fast free quote</h3>
            </div>
            <div className={'form-description'}>
                <p>Share your address and we will research your needs before we speak</p>
            </div>
            <form className={'email-form'} onSubmit={form.handleSubmit(onSubmit)}>

                <div className="field">
                    <Controller
                        name="firstname"
                        control={form.control}
                        rules={{ required: 'First name is required.', pattern: /^[a-zA-Z\d].*/ }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputText value={field.value || ''} onChange={field.onChange} placeholder="First Name"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />

                </div>

                <div className="field">
                    <Controller
                        name="lastname"
                        control={form.control}
                        rules={{ required: 'Last name is required.', pattern: /^[a-zA-Z\d].*/ }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputText value={field.value || ''} onChange={field.onChange} placeholder="Last Name"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />

                </div>

                <div className="field">
                    <Controller
                        name="address"
                        control={form.control}
                        rules={{ required: 'Address is required.', pattern: /^[a-zA-Z\d].*/ }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputText value={field.value || ''} onChange={field.onChange} placeholder="Address"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />
                </div>



                <div className="field">

                    <Controller
                        name="postCode"
                        control={form.control}
                        rules={{ required: 'Post Code is required.', pattern: { value: /^\d.*/, message: 'Please enter a valid post code', } }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputText value={field.value || ''} onChange={field.onChange} placeholder="Post Code"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />

                </div>

                <div className="field">
                    <Controller
                        name="email"
                        control={form.control}
                        rules={{
                            required: 'Email is required.', validate: {
                                maxLength: (v) =>
                                    v.length <= 50 || "The email should have at most 50 characters",
                                matchPattern: (v) =>
                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                    "Email address must be a valid address",
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputText value={field.value || ''} onChange={field.onChange} placeholder="Email"
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />


                </div>

                <div className="field">

                    <Controller
                        name="phone"
                        control={form.control}
                        rules={{ required: 'Phone is required.', pattern: /^\d.*/ }}
                        render={({ field, fieldState }) => (
                            <>


                                <InputMask mask="9999 999 999" placeholder="Phone" 
                                    value={field.value || ''} onChange={field.onChange}
                                    className={classNames({ 'p-invalid': fieldState.error })}
                                ></InputMask>


                                {getFormErrorMessage(field.name)}
                            </>
                        )} />
                </div>

                <div className="field">

                    <Controller
                        name="notes"
                        control={form.control}
                        rules={{ required: 'Notes is required.', pattern: /^[a-zA-Z\d].*/ }}
                        render={({ field, fieldState }) => (
                            <>

                                <InputTextarea value={field.value || ''} onChange={field.onChange} placeholder="Tell us about your unique need" 
                                    className={classNames({ 'p-invalid': fieldState.error })} />
                                {getFormErrorMessage(field.name)}

                            </>
                        )} />


                </div>


                <Button type="submit" label="Save" className="mt-2"  />

            </form>

        </div>
    );
};

export default Form;