import React, {useEffect, useState} from 'react';
import '../../../assets/styles/Works.css';
import $ from 'jquery';
import videoPoster from '../../../assets/image/banner-image.png'
import videoWorks from '../../../assets/video/Gutterguards.mp4';

const Works = ({userData: {worksTitle, worksDescription}}) => {
        const [playStatus, setPlayStatus] = useState(true);

        useEffect(() => {
            window.addEventListener("scroll", () => {
                    let scrollPos = $(document).scrollTop();
                    let videoStartPos = $('.works').offset().top;
                    let videoTagHeight = $('.works').height();
                    if ((scrollPos >= videoStartPos - 100) && (scrollPos <= videoStartPos + videoTagHeight) && playStatus===true) {
                        document.getElementById('video').play();
                    } else {
                        document.getElementById('video').pause();
                    }
                })
            }, [playStatus]
        )

        return (
            <div className={'works'}>
                <div className={'works-title'}>
                    <h1>{worksTitle}</h1>
                </div>
                <div className={'works-why'}>
                    <p>{worksDescription}</p>
                </div>

                <div className="video-wrapper" id={"video-wrapper"} onClick={() => {
                    setPlayStatus(!playStatus);
                }}>
                    {/*<video id={"video"} className="video" src={videoWorks} loop poster={videoPoster} controls*/}
                    {/*       controlsList="nodownload">*/}
                    {/*</video>*/}
                    {/*<div className="playpause"></div>*/}

                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xed8LWHbFtc?si=q8GbHzwZ7vSv8ddL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>

            </div>

        )
    }
;

export default Works;